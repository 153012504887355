var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("Searches"))),_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":""}},[_vm._v(_vm._s(_vm.totalItems))])],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-data-table',{staticClass:"fixed-table",attrs:{"fixed-header":"","height":"500","dense":"","headers":_vm.filters,"items":_vm.items,"options":_vm.options,"page":_vm.page,"server-items-length":_vm.totalItems,"loading":_vm.loading,"hide-default-footer":"","show-select":false,"loading-text":_vm.$t('Loading data'),"no-data-text":_vm.$t('No data'),"no-results-text":_vm.$t('No result')},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
var items = ref.items;
var headers = ref.headers;
return [_c('header-filters',{attrs:{"headers":_vm.filters}})]}},{key:"item",fn:function(options){return [_c('Row',{attrs:{"options":options,"show-select":true,"completed-field":"publication.state","config":{ url: '/searches/action/', resource: 'Wish'}},on:{"refetch-data":function($event){},"saved":function($event){},"select":function($event){}},scopedSlots:_vm._u([{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.user)?_c('v-avatar',{class:item.user.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.user.avatar ? '' : 'primary',"size":"30"}},[(item.user.avatar)?_c('v-img',{attrs:{"lazy-src":require("@/assets/images/avatars/2.png"),"src":item.user.avatar || require("@/assets/images/avatars/2.png")}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.user.name)))])],1):_vm._e(),(item.user)?_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'user-view', params : { id : item.user_id } }}},[_vm._v(" "+_vm._s(item.user.name)+" ")])],1):_vm._e()],1)]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.shortHtml(item.description, 50))}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list')],1)]}}],null,true)})]}}])}),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{attrs:{"lg":"2","cols":"3"}},[_c('rows-per-page',{attrs:{"options":_vm.options}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"10","cols":"9"}},[_c('v-pagination',{attrs:{"total-visible":"6","length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.$vuetify.theme.currentTheme.error,"light":_vm.$vuetify.theme.dark},model:{value:(_vm.isSnackbarBottomVisible),callback:function ($$v) {_vm.isSnackbarBottomVisible=$$v},expression:"isSnackbarBottomVisible"}},[_vm._v(" "+_vm._s(_vm.snackBarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }